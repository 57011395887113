import React, { useState } from 'react'
import P from '../../core/P';

const BlockComponent = block => (
    <>
        <div
            key={Math.random()}
            className="bg-white h-20 w-36 shadow-lg flex flex-wrap content-center justify-center rounded-md overflow-hidden relative"
        >
            <div className="w-auto h-12 flex-shrink-0 ">
                <img
                    src={block?.image?.mediaItemUrl}
                    alt={
                        block?.image?.altText ? block?.image?.altText : "Our Expertise"
                    }
                    title="Our Expertise"
                    width={block?.image?.mediaDetails?.width}
                    height={block?.image?.mediaDetails?.height}
                    loading="lazy"
                    className="w-auto h-12 mx-auto"
                />
            </div>
            <div className="flex items-center justify-center h-full w-full hover:bg-opacity-80 hover:bg-blue-500 absolute">
                {block?.image?.title && (
                    <P
                        title={block?.image?.title}
                        className={`hover:text-opacity-100 text-opacity-0 font-serif font-bold tracking-normal leading-6 text-lg text-white p-4 semibold flex items-center justify-center h-full  w-full text-center`}
                        noPad
                    />
                )}
            </div>
        </div>
    </>
)

export default function TechTabStackBlock({ TechTabStacks }) {
    const [current, setCurrent] = React.useState()
    const { division } = TechTabStacks
    const [openTab, setOpenTab] = useState(0)
    React.useEffect(() => {
        setCurrent(window.location.pathname)
    }, [])
    return (
        <div className="relative overflow-hidden " data-aos="fade-up">
            <div className={`mx-auto max-w-screen-xl px-4 ${division.length > 1 ? 'pt-5' : ''} relative`}>
                <div
                    className="flex w-full lg:justify-center md:justify-center  overflow-x-auto"
                    role="tablist"
                >
                    {division.length > 1 ?
                        division.map((e,index) => (
                            <div
                                className={`text-lg font-semibold lg:px-4 lg:py-2 px-3 py-3 mx-1 cursor-pointer flex flex-none items-end rounded ${openTab === index
                                    ? "bg-blue-500 text-white "
                                    : "text-blue-500 bg-white "
                                    }`}
                                onClick={() => {
                                    setOpenTab(index)
                                }}
                                data-toggle="tab"
                                aria-hidden="true"
                                role="tab"
                                key={Math.random()}
                            >
                                {e.title}
                            </div>
                        ))
                        : null
                    }
                </div>
                <div className="flex justify-center w-full overflow-x-auto">
                    <div className={`tab-content tab-space ${division.length > 1 ? 'py-12' : 'pb-12'}`}>
                        {division.filter((i,index) => index === openTab).map((e,ind) => (
                            <div
                                className={`w-full flex justify-center align-middle "
                                    }`}
                                id={ind}
                                key={Math.random()}
                            >
                                <div className={`grid ${e?.imagesArray?.length > 3 ? `lg:grid-cols-4 md:grid-cols-4` : `lg:grid-cols-${e?.imagesArray?.length} md:grid-cols-${e?.imagesArray?.length}`}  grid-cols-2 gap-5`}>
                                    {e.imagesArray &&
                                        e.imagesArray.map(({ TechStackTabCPT: block }, index) => (
                                            <React.Fragment key={index}>
                                                {block?.cta?.url ? (
                                                    <a
                                                        href={block?.cta?.url}
                                                        className="cursor-pointer"
                                                        target={`${current === "/gitex-technology-week/" ? "_blank" : ""}`}
                                                    >
                                                        {BlockComponent(block)}
                                                    </a>
                                                ) : (
                                                    <div className="cursor-default">
                                                        {BlockComponent(block)}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
