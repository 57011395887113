import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"
// import { InlineWidget } from "react-calendly"
import H2 from "../../core/H2";
const InlineWidget = loadable(() => import("react-calendly"))

const SimpleCalendly = () => {
    // eslint-disable-next-line no-unused-vars
    const [isDesktop, setIsDesktop] = useState(true);
    
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 991.98);
    };

    return (
        <div className="bg-transperent pt-5 " id="gitexfreepass">
            <div className=" grid grid-cols-1 lg:grid-cols-2">

                <div className="order-2 lg:order-1">
                    {isDesktop ? <div className="w-full min-h-screen">
                        <div className="flex justify-center items-center">
                            <InlineWidget
                                id="Desktop"
                                url="https://calendly.com/prafulk-1/30min"
                                styles={{
                                    height: "900px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div> :
                        <div className="w-full lg:hidden block mt-12">
                        <div className="flex justify-center w-full">
                            <InlineWidget
                                id="Mobile"
                                url="https://calendly.com/prafulk-1/30min"
                                styles={{
                                    height: "720px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                        }
                </div>
                <div className="flex items-center h-full order-1">
                    <div
                        className={`w-full bg-cover bg-center overflow-hidden`}
                    >
                        <div
                            className={`flex items-center h-full  w-full `}
                        >
                            <div
                                className="flex flex-col lg:items-start items-center lg:text-left md:text-center lg:py-16 m-4"
                            // data-aos={`${index === 0 ? "" : "fade-up"}`}
                            // data-aos-easing={`${index === 0 ? "" : "ease-out-back"}`}
                            // data-aos-duration={`${index === 0 ? "" : "700"}`}
                            >
                                <H2
                                    title="Meet Us at GITEX 2022"
                                    className='max-w-5xl text-start'
                                />
                                <p className={`pt-6 mx-auto text-lg font-sans max-w-4xl`}>With each passing year, WebMobTech has become an integral part of the Global Tech Show. Right from sharing our tech visions to brainstorming potential software development ideas, our team exhibits it all. Being in the digital transformation world for over a decade, we have developed over 300 mobile & web apps and worked with over 400 projects in almost all industries. Visit us at<span className="font-semibold text-blue-500"> Hall-5 Booth-1, DWTC from 10th Oct to  14th Oct 2022</span></p>
                                <div className="flex md:flex-row flex-col md:gap-9 gap-1">
                                    <a
                                        href="#gitexfreepass"
                                        className="mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                                    // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                                    >
                                        Let's have coffee together
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SimpleCalendly
