import React from "react"
// import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
// import P from "../../core/P"
// import H2 from "../../core/H2"

export const TitleSubtitleBlock = ({
  TitleSubtitleSliderBlock: { sliderGroup },
  // theme,
}) => {
  // const settings = {
  //   // className: "center",
  //   // centerMode: true,
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   pauseOnHover: true,
  //   focusOnSelect: true,
  //   arrows: true,
  //   resumeOnVisible: true,
  //   // variableWidth: true,
  //   useTransform: false,
  // }

  return (
    <div className="overflow-hidden">
      {/* <Slider {...settings}>
        {sliderGroup.map(({ backgroundCover, title, subtitle, cta }, i) => {
          const textColor = backgroundCover ? "text-white" : "text-shark-500"
          return (
            <div key={Math.random()}>
              <div
                className={`lg:w-full h-screen bg-cover bg-center md:w-full w-62 `}
                style={{
                  backgroundImage: `url(${backgroundCover?.mediaItemUrl})`,
                  backgroundColor: "black",
                }}
              >
                <div
                  className={`flex flex-col items-center justify-center h-full w-full bg-opacity-75 bg-black p-5 lg:p-10`}
                >
                  <div className="mx-auto max-w-5xl lg:p-0 md:p-10">
                    {title && (
                      <H2
                        title={title}
                        className={`${textColor}`}
                        theme={backgroundCover ? "#ffffff" : theme}
                      />
                    )}
                    {subtitle && (
                      <P
                        title={subtitle}
                        className={`${textColor} block text-white `}
                        // theme={backgroundCover ? "#ffffff" : theme}
                      />
                    )}
                    <div>
                      {cta && (
                        <p className="mt-6">
                          <a
                            href={cta.url}
                            className="inline-flex justify-center relative px-8 py-3 text-base leading-6 font-medium text-white bg-transperant hover:bg-white hover:text-shark-500 rounded border-white border-2"
                          >
                            {cta.title}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider> */}
    </div>
  )
}

export default TitleSubtitleBlock
