import React, { useEffect } from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import { useElementOnScreen } from "../../../util/hooks"

export const StatisticsBlock = ({
  title,
  subtitle,
  statisticsValues,
  theme,
  index
}) => {
  const [containerRef, isVisible] = useElementOnScreen([{
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }])
  useEffect(() => {
    if (isVisible) {
      document.querySelectorAll("#countk").forEach((el) => {
        window.kAnim(el, el.dataset.value, 3000);
      })
    }
  }, [isVisible])

  
  return (
    <div className="bg-transparent pt-12 sm:pt-16 state-trigger">
      {/* <span id="count1" class="display-4"></span> */}
      <div
        className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8"
        data-aos="fade-up"
        ref={containerRef}
      >
        <div className="max-w-4xl mx-auto flex flex-col justify-center items-center" >
          {title && <H2 title={title} className={`text-center`} index={index} />}
          {theme && title && (
            <hr
              className={`h-1 w-40 mt-5 border-0`}
              style={{ backgroundColor: theme }}
            />
          )}
          {subtitle && <P title={subtitle} className="text-shark-400 text-center" />}
        </div>
      </div>
      <div className="mt-20 pb-12 bg-transparent sm:pb-16" data-aos="fade-up" >
        <div className="relative">
          {/* <div className="absolute inset-0 h-1/2 bg-gray-50"></div> */}
          <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto"  >
              <div className="rounded-lg grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
                {statisticsValues&& statisticsValues.map(({ number, description }) => (
                  <div
                    key={Math.random()}
                    className="border-b bg-white border-gray-100 text-center sm:border-0 sm:border-r p-7 rounded-lg shadow-xl"
                  >
                    <h2
                      data-value={number}
                      className={`font-serif font-semibold  lg:text-5xl tracking-normal leading-10 text-3xl stats`}
                      style={{ color: theme ? theme : "#335fff" }}
                      id="countk"
                    >
                      {number}
                    </h2>
                    <P title={description} className="text-shark-400" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsBlock
