import React, { useState } from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

export const RightMediaLeftContentBlock = ({
  RightMediaLeftContentBlock,
  colorful,
  theme,
  index
}) => {
  // eslint-disable-next-line no-unused-vars
  const [showFullVideo, setShowFullVideo] = useState(false)
  const {
    component: {
      content: {
        align,
        description,
        title,
        tagline,
        tags = [],
        techImages = [],
        logo = {}
      } = {},
      image,
      video,
      videoPoster,
    },
    ctaLink,
    ctaLink2,
    blockWidth,
    animationColor: { bgColor, textColor },
  } = RightMediaLeftContentBlock
  const { title: ctaTitle, url } = ctaLink || {}
  const { mediaItemUrl, altText, mediaDetails } = image || {}
  const { width, height } = mediaDetails || {}
  // for logo
  const { mediaItemUrl: logo_mediaItemUrl, altText: logo_altText, mediaDetails: logo_mediaDetails } = logo || {}
  const { width: logo_width, height: logo_height } = logo_mediaDetails || {}
  const [current, setCurrent] = React.useState()
  React.useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])

  const fullWidth = blockWidth != null ? blockWidth[0] : null
  const handelVideo = () => {
    const video = document.getElementById("home-page-video");
    video.pause();
    setShowFullVideo(true)
  }
  const handlePlayVideo = () => {
    const video = document.getElementById("home-page-video");
    video.play();
    setShowFullVideo(false)
  }
  return (
    <>
      {showFullVideo ? (
        <div
          className="top-0 opacity-100 overflow-hidden w-screen h-screen fixed bg-shark-500 bg-opacity-80 left-0"
          style={{ transition: "opacity 0.5s ease-out", zIndex: 2000 }}
        >
          <div
            className="w-full h-full mx-auto mt-0"
            style={{
              padding: "0 1rem",
              transition: "all 0.5s ease-out",
              maxWidth: 940,
            }}
          >
            <div
              className="embed-container relative w-full max-w-full mt-0 top-1/2 transform -translate-y-1/2 h-0 bg"
              style={{ paddingBottom: "56.25%" }}
            >
              {current !== "/gitex-technology-week/" &&
                <div
                  className="absolute left-1/2 transform -translate-x-1/2 "
                  style={{ zIndex: 500, bottom: "-80px" }}
                >
                  {ctaLink && (
                    <div
                      className={`mt-10 inline-flex flex-row  justify-center  ${align === "Left" ? "lg:justify-start" : "lg:justify-end"
                        }`}
                    >
                      <a
                        href={url}
                        className="bg-blue-500 relative truncate  md:px-8 px-5 py-3 mr-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                      >
                        {ctaTitle}
                      </a>
                      <a
                        href={ctaLink2.url}
                        className="bg-blue-500 relative md:px-8 px-5 truncate  py-3   text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                      >
                        {ctaLink2.title}
                      </a>
                    </div>
                  )}
                </div>
              }
              <div
                id="video_close_btn"
                className="video_close_btn absolute right-0 opacity-100 cursor-pointer"
                aria-hidden="true"
                onClick={() => handlePlayVideo()}
                style={{
                  top: "-45px",
                  zIndex: 500,
                  fontFamily: "saileclight",
                  fontSize: "45px",
                  lineHeight: "25px",
                  color: "#ffffff",
                  transform: "rotate(45deg)",
                }}
              >
                +
              </div>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                id="home-page-video_full"
                preset="true"
                // preload="metadata"
                poster={videoPoster}
                loop=""
                width="100%"
                height="100%"
                autoplay=""
                data-filename={video}
                className="svg-clipped-text zoom home_page_video top-0 left-0 w-full h-full absolute"
                controls="controls"
                controlslist="nodownload"
              >
                Your browser does not support HTML5 video.
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={`${colorful ? "colorful" : ""
          } ${index === 0 || video ? "min-h-screen" : ""} relative  overflow-hidden flex justify-center align-middle`}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
        data-color={bgColor}
        data-text={textColor}
      >
        <div className="relative py-8 md:py-16 lg:py-20 xl:py-20 flex justify-center align-middle lg:my-auto">
          <div className={`w-screen ${fullWidth ? '' : 'px-4 sm:px-6 flex flex-wrap content-center justify-center item-center'} `}>
            <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:max-w-max mx-auto">
              <div
                className={`mt-12 md:mt-24 lg:mt-0 relative ${fullWidth ? "" : "sm:max-w-lg sm:mx-auto lg:max-w-none lg:mx-0"}  lg:col-span-6 lg:flex lg:items-center order-2`}
                data-aos={`${align === "Left" ? "fade-left" : "fade-right"}`}
              >
                {mediaItemUrl && (
                  <div
                    className={`relative w-full ${fullWidth ? '2xl:px-16 px-4' : `${!theme ? "lg:max-w-md mx-auto " : ""}`} rounded-lg  block`}
                  >
                    <div className="overflow-hidden flex justify-center">
                      <div className={`${fullWidth ? "w-full h-auto" : 'h-auto'}`}>
                        <img
                          src={mediaItemUrl}
                          alt={altText}
                          title={altText}
                          className={`${fullWidth ? 'w-full' : null}`}
                          height={height}
                          width={width}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {video && (
                  <div className="relative mx-auto w-full rounded-lg block lg:m-4 m-0">
                    <div className="relative group overflow-hidden flex justify-center">
                      <div className="h-auto">
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                          id="home-page-video"
                          preset="true"
                          // preload="none"
                          poster={videoPoster}
                          loop="loop"
                          muted="muted"
                          playsInline=""
                          autoPlay="autoplay"
                          data-filename={video}
                          className="w-full"
                          // controls="controls"
                          controlsList="nodownload"
                        >
                          Your browser does not support HTML5 video.
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                      <div
                        onClick={() => handelVideo()}
                        aria-hidden="true"
                        style={{ border: "3px solid white" }}
                        id="video-play-btn"
                        className="bg-transparent opacity-0 group-hover:opacity-100 cursor-pointer absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red z-10 left-1/2 w-32 h-32 expand video_play_btn"
                      >
                        <div
                          style={{
                            borderTop: "20px solid transparent",
                            borderBottom: "20px solid transparent",
                            borderLeft: "34px solid #fff",
                          }}
                          id="video-play-btn-icon"
                          className="opacity-0 group-hover:opacity-100 absolute w-0 h-0 border-b-20 border-t-20 border-l-34 inline-block top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1/2 revealPlay video_play_btn_icon"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={` mt-12 md:mt-24 lg:mt-0 text-center ${fullWidth ? "2xl:px-24 px-4 md:px-6 lg:px-12 xl:max-w-6xl" : "px-4"} lg:max-w-2xl mx-auto lg:col-span-6 flex flex-col justify-center order-1 ${align === "Left"
                  ? "lg:text-left md:text-left"
                  : "lg:text-right md:text-right"
                  }`}
                data-aos={`${align === "Left" ? "fade-right" : "fade-left"}`}
              >
                {logo && (<div className="w-full h-auto overflow-hidden flex md:block justify-center md:justify-start mb-6 md:mb-8">
                  <img
                    src={logo_mediaItemUrl}
                    alt={logo_altText ? logo_altText : "Technology Stack"}
                    title={logo_altText ? logo_altText : "Technology Stack"}
                    loading="lazy"
                    height={logo_height}
                    width={logo_width}
                  />
                </div>)}
                {title && <H2 title={title} className="" index={index} />}
                {title && theme && (
                  <div className="flex flex-col place-items-center md:place-items-start">
                    <hr
                      className="h-1 w-40 mt-5 border-0"
                      style={{ backgroundColor: theme }}
                    />
                  </div>
                )}

                {tagline && (
                  <div>
                    {/* <H3 title={tagline} className="mt-6 text-shark-500" /> */}
                    <h2
                      className={`font-serif font-semibold lg:text-3xl md:text-3xl text-2xl tracking-normal leading-10 mt-6 text-shark-500`}
                      dangerouslySetInnerHTML={{ __html: tagline }}
                    />
                  </div>
                )}
                {tags && (
                  <p className="mt-6">
                    {tags.map(({ name }) => (
                      <span
                        key={Math.random()}
                        className="text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent bg-white bg-opacity-10 shadow rounded"
                      >
                        {name}
                      </span>
                    ))}
                  </p>
                )}
                {description && (
                  <P title={description} noCenter className="text-shark-400" />
                )}
                {techImages && (
                  <div className="my-5 grid lg:grid-cols-6 md:grid-cols-6 grid-cols-3 gap-4">
                    {techImages.map(e => (
                      <>
                      {e?.iconsUrl?.url?
                        <a
                          href={e?.iconsUrl?.url}
                          key={Math.random(e?.iconsUrl?.url)}
                        >
                          <div className="flex justify-center align-middle">
                            <div className="m-3 w-12 h-12 flex justify-center align-middle">
                              {e?.techIcons?.mediaItemUrl && (
                                <img
                                  src={e?.techIcons?.mediaItemUrl}
                                  alt={e?.techIcons?.altText ? e?.techIcons?.altText
                                    : "Technology"}
                                  height={e?.techIcons?.mediaDetails?.height}
                                  width={e?.techIcons?.mediaDetails?.width}
                                  title={e?.techIcons?.altText ? e?.techIcons?.altText
                                    : "Technology"}
                                  loading="lazy"
                                />
                              )}
                            </div>
                          </div>
                          {e.techTitle && (
                            <P
                              title={e.techTitle}
                              className="text-shark-400 text-center font-semibold"
                            />
                          )}
                        </a>:
                        <>
                          <div className="flex justify-center align-middle">
                            <div className="m-3 w-12 h-12 flex justify-center align-middle">
                              {e?.techIcons?.mediaItemUrl && (
                                <img
                                  src={e?.techIcons?.mediaItemUrl}
                                  alt={e?.techIcons?.altText ? e?.techIcons?.altText
                                    : "Technology"}
                                  height={e?.techIcons?.mediaDetails?.height}
                                  width={e?.techIcons?.mediaDetails?.width}
                                  title={e?.techIcons?.altText ? e?.techIcons?.altText
                                    : "Technology"}
                                  loading="lazy"
                                />
                              )}
                            </div>
                          </div>
                          {e.techTitle && (
                            <P
                              title={e.techTitle}
                              className="text-shark-400 text-center font-semibold"
                            />
                          )}
                        </>
                        }
                      </>
                    ))}
                  </div>
                )}
                {ctaLink && (
                  <div
                    className={`mt-10 md:inline-flex justify-center  ${align === "Left" ? "lg:justify-start" : "lg:justify-end"
                      }`}
                  >
                    <div className="inline-flex rounded-full ">
                      <a
                        href={ctaLink.url}
                        className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
                        target={ctaLink.target}
                      >
                        {ctaLink.title}
                      </a>
                    </div>
                    {ctaLink2 && (
                      <div className="md:ml-3 mt-5 md:mt-0 inline-flex rounded-full ">
                        <a
                          href={ctaLink2.url}
                          className="inline-flex bg-transparent relative px-8 py-3 text-base leading-6 font-medium text-blue-500 hover:text-blue-600 rounded border border-blue-500 hover:border-blue-600"
                          target={ctaLink2.target}
                        >
                          {ctaLink2.title}
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightMediaLeftContentBlock
