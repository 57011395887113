import React, { useEffect, useRef, useState } from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

export const CenteredAccordion = ({ title, questions, firstid, theme }) => {
  const [q, setQ] = useState(0)

  const [defaultId, setDefaultId] = useState(firstid)

  const catMenu = useRef(null)
  const closeOpenMenus = (e) => {
    if (catMenu.current && !catMenu.current.contains(e.target)) {
      setDefaultId(null)
    }
  }
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener('mouseup', closeOpenMenus)
    }
    return () => {
      document.removeEventListener("mouseup", closeOpenMenus)
    }
  },[])
  

  return (
    <div className="bg-transparent mt-1"  >
      <div className="max-w-screen-xl mx-auto py-6 px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <div data-aos="fade-up">
            {title && <H2 title={title} theme={theme} />}
          </div>
          <div
            className="mt-6 border-t-2 border-gray-200 pt-6"
            data-aos="fade-up"
            ref={catMenu}
          >
            <dl>
              {questions.map(({ question, answer }, i) => (
                <div
                  className={`mt-3 p-5 bg-white rounded  ${i !== 0 ? "pt-6" : ""
                    }`}
                  key={Math.random()}

                >
                  <dt className="text-lg leading-7 ">
                    <button
                      className="text-left w-full  flex justify-between items-start text-shark-400 focus:outline-none focus:text-shark-600 "
                      onClick={() => {
                        setQ(i)
                        setDefaultId(i)
                      }}

                    >
                      <P
                        title={question}
                        className={`${i === q && defaultId !== null ? "text-blue-500" : "text-shark-500"
                          } text-left font-bold `}
                        noCenter
                        noPad
                      />
                      <span className="ml-6 h-7 flex items-center">
                        <svg
                          className={`h-6 w-6 transform ${i === q && defaultId !== null ? "" : "rotate-180"
                            }`}
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </button>
                  </dt>
                  <dd
                    className={`mt-2 pr-12 ${i === q && defaultId !== null ? "" : "hidden"
                      } text-left`}
                  >
                    <P
                      title={answer}
                      className="text-left text-shark-400"
                      noCenter
                    />
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CenteredAccordion
