import React from "react"
import P from "../../core/P"
import H2 from "../../core/H2"

export const CenterMediaContentBlock = ({
  CenterMediaContentBlock,
  colorful,
  theme,
  index,
  innerBlocks,
}) => {
  const {
    component: {
      content: { description, title, tagline, tags = [], listpoint = [] } = {},
      image,
      video,
      videoPoster
    },
    ctaLink,
    blockWidth,
    mediaType,
    animationColor: { bgColor, textColor },
  } = CenterMediaContentBlock
  const { title: ctaTitle, url } = ctaLink || {}
  const { mediaItemUrl, altText, mediaDetails } = image || {}
  const { height, width } = mediaDetails || {}

  const fullWidth = blockWidth != null ? blockWidth[0] : null
  // const divStyle = {
  //   background: `linear-gradient(to bottom,${bgColor}, transparent)`,
  // }
  // const divStyleTop = {
  //   background: `linear-gradient(to top,${bgColor}, transparent)`,
  // }
  return (
    <div
      className={` ${colorful ? "colorful" : ""} relative ${video ? "min-h-screen flex justify-center items-center" : ""}`}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
      data-color={bgColor}
      data-text={textColor}
    >
      {/* {colorful && index !== 0 ? (
        <div className="blurred-effect-bg-top " style={divStyleTop} />
      ) : (
        ""
      )} */}
      <div className="common-index relative pt-3 pb-10 md:pb-20 lg:pb-24 xl:pb-16">
        <div className={`${video ? "lg:mt-20" :"lg:mt-8"} mx-auto ${fullWidth ? "" : "max-w-screen-xl px-4 sm:px-6"}  mt-12  md:mt-20 xl:mt-24`}>
          <div
            className={`flex flex-col justify-center ${fullWidth ? "px-4 lg:p-0 sm:px-6" : ""} items-center text-center max-w-6xl md:mx-auto lg:col-span-6 mb-12`}
            data-aos={`fade-up`}
            data-aos-duration={`400`}
            data-aos-easing={`ease`}
          >
            {title && (
              <H2 title={title} textColor={textColor} index={index} />
            )}
            {theme && title && !tags && (
              <hr
                className="h-1 w-40 mt-5 border-0"
                style={{ backgroundColor: theme }}
              />
            )}
            {tagline && (
              <div className="mt-8 text-center">
                <P
                  className="lg:text-3xl md:text-2xl text-lg font-semibold"
                  style={{ color: textColor ? textColor : "#24262B" }}
                  noPad
                  title={tagline}
                />
              </div>
            )}
            {tags && (
              <p className="mt-8">
                {tags.map(({ name }) => (
                  <span
                    key={Math.random()}
                    className="text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent  bg-white bg-opacity-10 shadow rounded"
                  >
                    {name}
                  </span>
                ))}
              </p>
            )}
            {description && (
              <P
                title={description}
                style={{ color: textColor ? textColor : "#3D4048" }}
              />
            )}
            {
              listpoint&& (
                <ul className="text-left mt-6 max-w-3xl px-4">
                  {listpoint.map((val, index) => {
                    return <li className="list-disc" key={index}>{val?.list}</li>
                  })}
                </ul>
              )
            }
            {ctaLink && (
              <div className="mt-5 inline-flex ">
                <a
                  href={url}
                  className={`${colorful
                      ? "relative px-8 py-3 text-base leading-6 font-medium text-white bg-transperant hover:bg-white hover:text-shark-500 rounded border-white border-2"
                      : "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                    }`}
                >
                  {ctaTitle}
                </a>
              </div>
            )}
          </div>

          <div
            className={`relative ${fullWidth ? "" : "sm:max-w-lg sm:mx-auto"} lg:max-w-none lg:col-span-6 flex items-center `}
            data-aos={`fade-up`}
            data-aos-duration={`400`}
            data-aos-easing={`ease`}
          >
            <div className={
              fullWidth ? "relative mx-auto w-full rounded-lg flex flex-col" :
                "relative mx-auto w-auto rounded-lg lg:max-w-screen-lg flex flex-col"
            }>
              <div className="w-full h-auto">
                {mediaItemUrl && mediaType === 'Image' && (
                  <img
                    src={mediaItemUrl}
                    alt={altText ? altText : "Technology"}
                    title={altText ? altText : "Technology"}
                    loading="lazy"
                    className={fullWidth ? 'w-full' : "lg:max-w-screen-lg"}
                    width={width}
                    height={height}
                  />
                )}

                {
                  video && mediaType === "Video" && (
                    <div className="relative mx-auto w-full max-w-3xl rounded-lg block lg:m-4 m-0">
                    <div className="relative group overflow-hidden flex justify-center">
                      <div className="h-auto">
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                          id="home-page-video"
                          preset="true"
                          // preload="none"
                          poster={videoPoster}
                          loop="loop"
                          muted="muted"
                          playsInline=""
                          // autoPlay="autoplay"
                          data-filename={video}
                          className="w-full"
                          controls="controls"
                          controlsList="nodownload"
                        >
                          Your browser does not support HTML5 video.
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                  
                    </div>
                  </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {colorful && index !== innerBlocks.length - 1 ? (
        <div className="blurred-effect-bg " style={divStyle} />
      ) : (
        ""
      )} */}
    </div>
  )
}

export default CenterMediaContentBlock
