import React, { useEffect } from 'react'
import { useElementOnScreen } from '../../../util/hooks'
import H2 from '../../core/H2'
import P from '../../core/P'

export default function SimpleInBrand({
    title,
    subtitle,    
    statisticsValues,
    theme,
    index
  })  {
    const [containerRef, isVisible] = useElementOnScreen([{
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    }])
    useEffect(() => {
        if (isVisible) {
            document.querySelectorAll("#countk").forEach((el) => {
                window.kAnim(el, el.dataset.value, 3000);
            })
        }
    }, [isVisible])
    return (
        <div className=' bg-white py-12' >
            <div className='max-w-6xl mx-auto bg-no-repeat bg-center py-28 px-4'  style={{ backgroundImage: "url(https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Map.png)",backgroundSize:"100% auto" }}>
                <H2 title={title} className="text-center" />
                <div className='grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 md:gap-y-16 md:gap-x-12 justify-center text-center mx-auto md:pt-16 pt-10' >
                    {statisticsValues&&(
                        statisticsValues.map(({ number, description }) => {
                        return <div className=' '>
                        <h2
                            data-value={number}
                            className={`font-serif  lg:text-5xl tracking-normal leading-10 text-3xl stats text-blue-500 font-extrabold`}
                              style={{ color: theme ? theme : "#335fff" }}
                            id="countk"
                            ref={containerRef}
                        >
                            {number}
                        </h2>
                        <P title={description} className="text-shark-400 font-bold md:pt-6" noPad={true}/>
                    </div>
                        }))
                    }
                </div>
            </div>
        </div>
    )
}
