import React from "react"
import { splitArrayIntoChunksOfLen } from "./../../../util/helper"
import H2 from "../../core/H2"
import P from "../../core/P"

export const TwoColumnsV2 = ({ title, questions, theme }) => {
  if (!theme) {
    theme = "#335fff"
  }
  const q = splitArrayIntoChunksOfLen(questions, questions.length / 2)
  return (
    <div className="bg-transparent">
      <div className="max-w-screen-xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
        <div
          data-aos="fade-up"
          className="flex flex-col justify-center items-center text-center"
        >
          {title && <H2 title={title} className="text-center" />}
          {theme && title && (
            <hr
              className="h-1 w-40 mt-5 border-0"
              style={{ backgroundColor: theme }}
            />
          )}
        </div>
          <div
            className="md:grid md:grid-cols-2 md:gap-8 mt-8"
            data-aos="fade-up"
          >
            {q[0].map(({ question, answer }, i) => (
              <div className="md:mt-5 mt-10" key={Math.random()}>
                <p
                  dangerouslySetInnerHTML={{ __html: question }}
                  style={{ color: theme ? "#3D4048" : "#335fff" }}
                  className="font-bold text-lg font-sans max-w-4xl"
                />
                <P title={answer} noPad className="mt-3 text-shark-400" />
              </div>
            ))}
            {q[1]?.map(({ question, answer }, i) => (
              <div className="md:mt-5 mt-10" key={Math.random()}>
                <p
                  dangerouslySetInnerHTML={{ __html: question }}
                  style={{ color: theme ? "#3D4048" : "#335fff" }}
                  className="font-bold text-lg font-sans max-w-4xl"
                />
                <P title={answer} noPad className="mt-3 text-shark-400" />
              </div>
            ))}
            {/* <div className="mt-12 md:mt-0" data-aos="fade-up">
              {q[1]?.map(({ question, answer }, i) => (
                <div className={i !== 0 ? "mt-12" : ""} key={Math.random()}>
                  <p
                    dangerouslySetInnerHTML={{ __html: question }}
                    style={{ color: theme ? "#3D4048" : "#335fff" }}
                    className="font-bold text-lg font-sans max-w-4xl"
                  />
                  <P title={answer} noPad className="mt-3 text-shark-400" />
                </div>
              ))}
            </div> */}
          </div>
      </div>
    </div>
  )
}

export default TwoColumnsV2
