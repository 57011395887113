import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Countries from "../../../../static/countries.json"
import H2 from "../../core/H2"


const NewContactForm = () => {
  const [nda, setNda] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [message, setMessage] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [current, setCurrent] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [optionsCountry, setOptionsCountry] = useState([])

  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])

  const changeHandler = e => {
    setCountryCode(e.target.value)
  }
  const handleOption = () => {
    const test = Countries
    setOptionsCountry(test)
  }
  const onSubmit = e => {
    e.preventDefault()
    setDisabled(true)
    const form = e.target
    const data = new FormData(form)
    const GCL_ID =
      JSON.parse(localStorage.getItem("gclid")) !== null &&
        JSON.parse(localStorage.getItem("gclid")).value !== null
        ? JSON.parse(localStorage.getItem("gclid")).value
        : undefined
    data.set("countryCode", countryCode)
    data.set("gclid", GCL_ID)
    data.set("nda", nda ? "1" : "0")
    data.set("companyName", "")
    data.set("formType", "Footer Contact Form")
    data.set("pageURL", `https://webmobtech.com` + current)

    // eslint-disable-next-line no-unused-vars
    const response = fetch(
      // "http://127.0.0.1:8080/api/web/contactus/contact_submit",
      "https://api.webmobtech.com/wmtblog/api/web/contactus/contact_submit",
      {
        method: "POST",
        body: data,
      }
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res.meta.status === "ok") {
          document.getElementById("contact-form").reset()
          setNda(false)
          navigate("/thank-you/")
        }
        setDisabled(false)
        setMessage(res.meta.message)
        setStatus(res.meta.status)
      })
  }

  const Notification = ({ status, message }) => {
    if (!status) return null
    return (
      status && (
        <div
          className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
            } p-4`}
        >
          <div className="flex">
            <div className="ml-3">
              <p
                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                  }`}
              >
                {message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setStatus(false)}
                  className={`inline-flex rounded-md p-1.5 ${status === "ok"
                    ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                    : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                    }  focus:outline-none transition ease-in-out duration-150`}
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
  return (
    <div className="common-index">
      <div className=" rounded-md   overflow-hidden "> 
        <div className="relative">
          <form
            id="contact-form"
            method="POST"
            className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-5 lg:grid-cols-2 lg:gap-6"
            onSubmit={e => onSubmit(e)}
          >
            {status && (
              <div className="sm:col-span-2">
                <Notification status={status} message={message} />
              </div>
            )}
            <div className="lg:col-span-2 md:col-span-2 col-span-2 mt-1 relative ">
              <H2 title="Let's Work Together!" className="pb-6 text-center md:text-left " />
            </div>
            <div className="lg:col-span-1 md:col-span-1 col-span-2 mt-1 relative ">
              <input
                id="fullName"
                name="fullName"
                placeholder="Full Name *"
                required
                className="input_custom_placeholder rounded-md bg-transparent form-input border-shark-100 py-3 mt-2 px-4 block w-full border border-solid transition ease-in-out duration-150"
              />
            </div>
            <div className="lg:col-span-1 md:col-span-1 col-span-2 mt-1 relative ">
              <input
                // type="tel"
                id="contactNo"
                name="contactNo"
                required
                placeholder="Phone *"
                className="input_custom_placeholder rounded-md bg-transparent form-input py-3 mt-2 px-4 block w-full border border-solid  border-shark-100 transition ease-in-out duration-150"
              />
            </div>
            <div className="col-span-2  relative ">         
              <input
                id="emailId"
                name="emailId"
                type="email"
                placeholder=" Email *"
                required
                className="input_custom_placeholder rounded-md bg-transparent form-input py-3 mt-2 px-4 block w-full border border-solid  border-shark-100 transition ease-in-out duration-150"
              />
            </div>
            <div className="col-span-2 flex flex-wrap">
              <div className="md:w-1/3 sm:w-1/2 w-full lg:pr-0 md:pr-2 mt-1 relative ">                
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}                
                <select
                  aria-label="Country"
                  name="countryCode"
                  id="countryCode"
                  value={countryCode}
                  onFocus={handleOption}
                  onChange={changeHandler}
                  required
                  className=" rounded-md bg-transparent select-height form-select py-3 mt-2 px-4 block w-full h-full border border-solid text-shark-300 border-shark-100 transition ease-in-out duration-150"
                >
                  <option
                    value=""
                    className="custom_option text-shark-500"
                  >
                    Country *
                  </option>
                  {optionsCountry && optionsCountry.map(e => (
                    <option
                    className="custom_option text-shark-500"
                      key={Math.random()}
                    >
                      {e?.code}&nbsp;&nbsp;{e?.flag}
                    </option>
                  ))}
                </select>
              </div>
              <div className="md:w-2/3 sm:w-1/2 w-full lg:pl-6 md:pl-2 mt-5 md:mt-1 relative ">          
                <input
                  id="skypeOrWhatsapp"
                  name="skypeOrWhatsapp"
                  placeholder=" Skype ID/WhatsApp No."
                  className="input_custom_placeholder rounded-md bg-transparent form-input py-3 mt-2 px-4 block w-full border border-solid  border-shark-100 transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="col-span-2 mt-1 relative ">              
              <textarea
                id="desc"
                name="desc"
                rows="4"
                // required
                placeholder="  Message"
                className="input_custom_placeholder rounded-md bg-transparent form-textarea resize-none py-3 mt-2 px-4 block w-full border border-solid  border-shark-100 transition ease-in-out duration-150"
              ></textarea>
            </div>
            <div className="col-span-2 ">
              <span className="inline-flex items-center">
              <span
                    className={`${nda ? "bg-blue-500" : "bg-blue-300"
                      } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                    role="checkbox"
                    aria-checked="false"
                    aria-hidden="true"
                    onClick={() => setNda(s => !s)}
                    id="nda"
                    name="nda"
                  >
                    <span
                      className={` ${nda ? "translate-x-5" : "translate-x-0"
                        } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                    ></span>
                  </span>
                  <span className="text-shark-400 text-lg font-medium pl-4">
                  Send NDA
                </span>      
              </span>
            </div>
            <div className="flex flex-wrap col-span-2 justify-end">  
                  <div className=" mt-5  ">
                    {disabled ?
                      <button
                        type="submit"
                        disabled
                        className="text-white relative px-8 py-3 text-base leading-6 font-medium bg-blue-300 opacity-75 cursor-not-allowed rounded"
                      >
                        Let's talk
                      </button> :
                      <button
                        type="submit"
                        className="text-white w-full relative px-8 py-3 text-base leading-6 font-medium bg-blue-500 hover:bg-blue-600 rounded"
                      >
                        Let's talk
                      </button>}            
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NewContactForm)