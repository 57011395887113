import React, { useState, useEffect } from "react"
import ContactForm from "../ContactForm"
import AddsForm from "../Forms/AddsForm"
import { addsLinks } from "../../../util/conditionalLinks"

const ContactUs = () => {

  const [current, setCurrent] = useState()

  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])

  return (
    <div id="contactform" className="">
      <div className="relative bg-blue-500 md:mt-28 lg:mt-44 mt-10 lg:px-12 px-4">
        <div className="flex xl:max-w-6xl lg:max-w-5xl md:max-w-2xl mx-auto bg-white shadow-2xl rounded-lg lg:py-18 py-12 md:px-12 px-4 relative md:-top-28 -top-10">
          <div className="lg:w-2/3 justify-center items-center mx-auto lg:pr-8">
            {addsLinks.includes(current) ?
              <AddsForm /> : <ContactForm />}
          </div>
          <div className=" w-1/3 lg:flex justify-end hidden items-center">
            <img
              src={`https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/09/Footer.png`}
              alt="contact us form"
              className="h-full w-auto"
              height={571}
              width={285}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
