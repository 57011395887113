import React from "react"
import Title from "../../core/H2"
import P from "../../core/P"

export const SimpleCentred = ({ title, subtitle, action1, action2, theme }) => {
  return (
    <div className="bg-transparent">
      <div
        className="md:w-2/3 w-full mx-auto text-center py-12 px-4"
        data-aos="fade-up"
      >
        <div className="w-full flex flex-col text-center place-items-center">
          {title && (
            <Title title={title} className="max-w-4xl text-center" />
          )}
          {title && theme && (
            <hr
              className={`h-1 w-40 mt-5 border-0`}
              style={{ backgroundColor: theme }}
            />
          )}
          {subtitle && <P title={subtitle} className="text-shark-400" />}
        </div>
        <div className="mt-8 flex justify-center">
          {action1 && (
            <div className="inline-flex">
              <a
                href={action1.url}
                className="bg-blue-500 inline-flex relative px-5 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded shadow-lg"
              >
                {action1.title}
              </a>
            </div>
          )}
          {action2 && (
            <div className="ml-3 inline-flex">
              <a
                href={action2.url}
                className="inline-flex relative px-5 py-3 text-base leading-6 font-medium border-2 bg-transparent border-blue-500 text-blue-500 hover:border-blue-600 hover:text-blue-600  rounded"
              >
                {action2.title}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default SimpleCentred
