import React, { useState } from "react"
import PoptinTwo from "./../Forms/PopupForm"

const ButtonPopup = () => {
    const [gitexPopup, setGitexPopup] = useState(false)
    return (
        <div className="flex justify-center iteam-center">
            {gitexPopup && (
                <>
                    <PoptinTwo setGitexPopup={setGitexPopup} />
                </>
            )}
            <div
                role="button"
                onClick={() => {
                    setGitexPopup(true)
                }}
                aria-hidden="true"
                className="mx-6 my-5 md:my-8 relative px-6 py-3 text-base leading-6 font-medium text-white rounded cursor-pointer text-center"
                style={{ backgroundColor: "#DB264D" }}
            >
                Get a Free Pass & Meet us at GITEX Dubai 17th Oct to 21st Oct 2021!
            </div>
        </div>
    )
}

export default ButtonPopup
