import { useRef, useEffect, useState } from "react"

export const useElementOnScreen = (options) => {
    const containerRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const callbackFunction = (entries) => {
            const [entry] = entries
            if (!isVisible) {
                setIsVisible(entry.isIntersecting)
            }
        }
        const observer = new IntersectionObserver(callbackFunction, options)
        const cRef = containerRef?.current
        if (cRef) observer.observe(cRef)

        return () => {
            if (cRef) observer.unobserve(cRef)
        }
    }, [containerRef, options, isVisible])

    return [containerRef, isVisible]
}
