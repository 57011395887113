import React, { useState } from "react"

const Index3 = () => {
    const [showFullVideo, setShowFullVideo] = useState(false)
    return (
        <>
            {showFullVideo ? (
                <div
                    className="top-0 opacity-100 overflow-hidden w-screen h-screen fixed bg-shark-500 bg-opacity-80 left-0"
                    style={{ transition: "opacity 0.5s ease-out", zIndex: 2000 }}
                >
                    <div
                        className="w-full h-full mx-auto mt-0"
                        style={{
                            padding: "0 1rem",
                            transition: "all 0.5s ease-out",
                            maxWidth: 940,
                        }}
                    >
                        <div
                            className="embed-container relative w-full max-w-full mt-0 top-1/2 transform -translate-y-1/2 h-0 bg"
                            style={{ paddingBottom: "56.25%" }}
                        >
                            <div
                                id="video_close_btn"
                                className="video_close_btn absolute right-0 opacity-100 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => setShowFullVideo(false)}
                                style={{
                                    top: "-45px",
                                    zIndex: 500,
                                    fontFamily: "saileclight",
                                    fontSize: "45px",
                                    lineHeight: "25px",
                                    color: "#ffffff",
                                    transform: "rotate(45deg)",
                                }}
                            >
                                +
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video
                                id="home-page-video_full"
                                preset="true"
                                // preload="metadata"
                                poster="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/10/1920x1080-with-logo-1-2.jpg"
                                loop=""
                                width="100%"
                                height="100%"
                                autoplay=""
                                data-filename="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/static/videos/webmobtech_gitex_2020.mp4"
                                className="svg-clipped-text zoom home_page_video top-0 left-0 w-full h-full absolute"
                                controls=""
                                controlslist="nodownload"
                            >
                                Your browser does not support HTML5 video.
                                <source src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/static/videos/webmobtech_gitex_2020.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            ) : null}
            <div
                className={`w-full bg-cover bg-center min-h-screen overflow-hidden`}
                style={{
                    backgroundImage: `url(https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/10/Gitex-header-image-1-1-1.jpg)`,
                    backgroundColor: "bg-shark-500",
                }}
            >
                <div className="w-full flex lg:flex-row flex-col items-center min-h-screen ">
                    <div className="lg:w-4/12 w-full flex justify-center lg:px-6 px-4">
                        <div className="flex justify-center flex-col ">
                            <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-28">
                                <img
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/10/Gitex-Global-x-GFS-white.png"
                                    alt="Gitex"
                                    height="110px"
                                    width="440px"
                                    className="w-full h-auto"
                                    loading="lazy"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `GITEX TECHNOLOGY WEEK 2021`,
                                    }}
                                    className="font-serif font-semibold lg:text-6xl text-3xl tracking-normal pt-10 text-blue-200 lg:text-left text-center"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Event date: 17th to 21st October`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mt-6 px-2 rounded lg:text-left text-center"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Meet at: Z5-334, Za'abeel Hall 5, DWTC`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mt-4 px-2 bg-blue-600 rounded lg:text-left text-center"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-8/12 w-full my-10 md:my-24 lg:my-0 ">
                        <div className="flex justify-center items-center">
                            <div className="bg-white w-4/5 rounded-md">
                                <div className="relative mx-auto rounded-md block">
                                    <div className="relative group overflow-hidden flex justify-center rounded-md">
                                        <div className="h-auto">
                                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                            <video
                                                id="home-page-video"
                                                preset="true"
                                                // preload="metadata"
                                                poster="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/10/1920x1080-with-logo-1-2.jpg"
                                                loop="loop"
                                                muted="muted"
                                                playsInline=""
                                                autoPlay="autoplay"
                                                data-filename="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/static/videos/webmobtech_gitex_2020.mp4"
                                                className="w-full"
                                                // controls="controls"
                                                controlsList="nodownload"
                                            >
                                                Your browser does not support HTML5 video.
                                                <source src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/static/videos/webmobtech_gitex_2020.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                        <div
                                            onClick={() => setShowFullVideo(true)}
                                            aria-hidden="true"
                                            style={{ border: "3px solid white" }}
                                            id="video-play-btn"
                                            className="bg-transparent opacity-0 group-hover:opacity-100 cursor-pointer absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red z-10 left-1/2 w-32 h-32 expand video_play_btn"
                                        >
                                            <div
                                                style={{
                                                    borderTop: "20px solid transparent",
                                                    borderBottom: "20px solid transparent",
                                                    borderLeft: "34px solid #fff",
                                                }}
                                                id="video-play-btn-icon"
                                                className="opacity-0 group-hover:opacity-100 absolute w-0 h-0 border-b-20 border-t-20 border-l-34 inline-block top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1/2 revealPlay video_play_btn_icon"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index3
