import React from "react"
import H2 from "../../core/H2"

export const SideBySide = ({ title, questions, theme }) => {
  if (!theme) {
    theme = "#335fff"
  }
  return (
    <div className="bg-transparent mt-5">
      <div className="max-w-screen-xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <H2 data-aos="fade-up"   title={title} />
        <div
          className="mt-6 border-t-2 border-gray-200 pt-6"
          data-aos="fade-up"  
        >
          <dl>
            {questions.map(({ question, answer }, i) => (
              <div
                className={`md:grid md:grid-cols-12 md:gap-8 ${
                  i !== 0 ? "mt-8 border-t border-gray-200 pt-6" : ""
                }`}
                key={Math.random()}
              >
                <dt
                  className="tleading-6 md:col-span-5 font-bold text-lg font-sans max-w-4xl"
                  style={{ color: theme }}
                  dangerouslySetInnerHTML={{ __html: question }}
                />
                <dd className="mt-2 md:mt-0 md:col-span-7 ">
                  <p
                    className="text-base leading-6 "
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SideBySide
