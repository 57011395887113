import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import Twitter from "../../../assets/images/store/Twitter.svg"
import LinkedIn from "../../../assets/images/store/LinkedIn.svg"

const AuthorProfile = ({ author }) => {
  return (
    <div className="lg:px-0 md:px-0 pt-8 pb-20 px-4">
      <div
        className="flex mx-auto lg:max-w-screen-lg md:max-w-screen-sm max-w-screen-sm justify-center items-center bg-blue-200 rounded-md"
        data-aos="fade-up"
      >
        <div className="grid grid-cols-6 lg:gap-6 md:gap-12 gap-6 lg:py-6 md:py-10 py-12">
          <div className="lg:col-span-2 md:col-span-6 col-span-6 flex flex-wrap content-center">
            <div className="flex flex-wrap content-center mx-auto relative">
              <a href={`/blog` + author?.node?.uri}>
                <div className="self-center lg:w-52 lg:h-52 md:w-52 md:h-52 w-40 h-40">
                  <img
                    src={author?.node?.avatar?.url}
                    alt={author?.node?.name}
                    width="180"
                    className="w-60 rounded-full border-4 border-gray-300"
                    height="180"
                    loading="lazy"
                  />
                  <div className="lg:w-10 lg:h-10 md:w-10 md:h-10 w-6 h-6 absolute lg:top-12 md:top-12 top-10 lg:-left-1 -left-1 md:-left-1 rounded-full bg-yellow-300" />
                  <div className="lg:w-16 lg:h-16 md:w-16 md:h-16 w-12 h-12 absolute lg:bottom-6 bottom-6 md:bottom-6 lg:-right-1 -right-2 md:-right-1 rounded-full bg-blue-500" />
                </div>
              </a>
            </div>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-6 p-4">
            <a href={`/blog` + author?.node?.uri}>
              <H2
                title={author?.node?.name}
                className="lg:text-left md:text-center text-center"
              />
              <P
                title={author?.node?.description}
                className="lg:text-left md:text-center text-center text-shark-400"
              />
            </a>
            <div className="flex lg:justify-start md:justify-center justify-center  items-center">
              <a
                href={author?.node?.seo?.social?.linkedIn}
                className=" py-5 inline-block text-white"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedIn} alt="linkdin" className="h-8" loading="lazy" width="32px" height="32px"/>
                <span className="sr-only">LinkedIn</span>
              </a>
              <a
                href={author?.node?.seo?.social?.twitter}
                className=" py-5 inline-block  text-white ml-5"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Twitter} alt="twitter" className="h-8" loading="lazy" width="32px" height="32px"/>
                <span className="sr-only">Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorProfile
