import React from "react"
import loadable from "@loadable/component"
const CenterMediaContentBlock = loadable(() => import("../CenterMediaContentBlock"))
const LeftMediaRightContentBlock = loadable(() => import("../LeftMediaRightContentBlock"))
const RightMediaLeftContentBlock = loadable(() => import("../RightMediaLeftContentBlock"))


export const ColorComponent = ({ innerBlocks, theme }) => {

  return (
    <div>
      {/* eslint-disable-next-line array-callback-return */}
      {innerBlocks.map((i, index) => {
        switch (i.name) {
          case "acf/center-media-center-content":
            return (
              <CenterMediaContentBlock
                {...i}
                colorful
                theme={theme}
                key={Math.random()}
                index={index}
                innerBlocks={innerBlocks}
              />
            )
          case "acf/left-media-right-content":
            return (
              <LeftMediaRightContentBlock
                {...i}
                colorful
                theme={theme}
                key={Math.random()}
                index={index}
                innerBlocks={innerBlocks}
              />
            )
          case "acf/right-media-left-content":
            return (
              <RightMediaLeftContentBlock
                {...i}
                colorful
                theme={theme}
                key={Math.random()}
                index={index}
                innerBlocks={innerBlocks}
              />
            )
          default:
            console.log(i)
        }
      })}
    </div>
  )
}

export default ColorComponent
