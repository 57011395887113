import React from "react"
import loadable from "@loadable/component"
const SideBySide = loadable(() => import("./SideBySide"))
const TwoColumns = loadable(() => import("./TwoColumns"))
const CenteredAccordion = loadable(() => import("./CenteredAccordion"))

export const FAQ = ({ FAQ, theme }) => {
  const { type } = FAQ
  switch (type) {
    case "side-by-side":
      return <SideBySide {...FAQ} theme={theme} />
    case "two columns":
      return <TwoColumns {...FAQ} theme={theme} />
    case "centered accordion":
      return <CenteredAccordion {...FAQ} theme={theme} />
    default:
      break
  }
}

export default FAQ
