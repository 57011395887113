import React from "react"
import loadable from "@loadable/component"
import { useStaticQuery } from "gatsby"
const LogoCloud = loadable(() => import("./LogoCloud"))
const LogoSlider = loadable(() => import("./LogoSlider"))

export const Logo = ({ Logos }) => {
  const logodata = useStaticQuery(graphql`
    query LogosQuery{
      allWpLogo {
        nodes {
          LogoCPT {
            image {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }
  `)
  const data = { ...logodata, ...Logos }
  const { type } = Logos
  switch (type) {
    case "Grid":
      return <LogoCloud {...data} />
    case "Slider":
      return <LogoSlider {...data} />
    default:
      break
  }
  return (
    <>
    </>
  )
}

export default Logo
