import React from "react"

export const SpacerBlock = ({ SpacerBlock }) => {
  const {
    spacer: { desktop, mobile },
  } = SpacerBlock
  const getClass = (d, m) => {
    return `py-${m} md:py-${d} lg:py-${d}`
  }

  return <div className={getClass(desktop, mobile)} />
}

export default SpacerBlock
