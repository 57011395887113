import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

export const PDFViewer = ({ PDFViewerBlock }) => {
  const { pdfLink,title,subtitle } = PDFViewerBlock
  // console.log("PDFViewerBlock", pdfLink)
  return (
    <div
      className="max-w-screen-xl mx-auto text-center py-12 px-4 bg-transparent"
      data-aos="fade-up"
    >
      <div data-scroll data-scroll-speed="2" className="py-40">
        <H2 title={title} />
        <P
          title={subtitle}
          className="text-shark-400"
        />
      </div>
      <div className="min-w-full">
        <iframe
          title="Company Profile"
          className="w-full h-screen "
          src={pdfLink}
          allowFullScreen="true"
          webkitallowfullscreen="true"
        />
      </div>
    </div>
  )
}

export default PDFViewer
