import React, { useState } from "react"

const SubscribeForm = () => {
  const [email, setEmail] = useState({
    useremail: '',
    response: null,
    success: null,
    loading: false,
  })
  const onSubmit = e => {
    e.preventDefault()
    setEmail(prevState => ({
      ...prevState,
      loading: true
    }));
    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/2902222/4fdb29e4-89a2-4817-82a6-057581c15ff2"
    // In var url above, replace portalId and formId with your values from HubSpot.
    // Example request JSON:
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email?.useremail,
        },
      ],
      context: {
        pageUri: "subscribe-success",
        pageName: "Subscribe successful",
      },
    }
    var final_data = JSON.stringify(data)
    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function () {
      try {
        var response = xhr.responseText
        var data = JSON.parse(response);
      } catch (error) {
        console.error(error);
      }
      if (xhr.readyState === 4 && xhr.status === 200) {
        setEmail(prevState => ({
          ...prevState,
          response: data.inlineMessage,
          success: true,
          loading: false
        })); // Returns a 200 data.message if the submission is successful.
      }
      if (xhr.readyState === 4 && xhr.status === 400) {
        setEmail(prevState => ({
          ...prevState,
          response: data.message,
          success: false,
          loading: false
        }));// Returns a 400 error the submission is rejected.
      }
      if (xhr.readyState === 4 && xhr.status === 403) {
        setEmail(prevState => ({
          ...prevState,
          response: data.message,
          success: false,
          loading: false
        })); // Returns a 403 error if the portal isn't allowed to post submissions.
      }
      if (xhr.readyState === 4 && xhr.status === 404) {
        setEmail(prevState => ({
          ...prevState,
          response: data.message,
          success: false,
          loading: false
        }));//Returns a 404 error if the formGuid isn't found
      }
    }
    // Sends the request
    xhr.send(final_data)
    setEmail(prevState => ({ ...prevState, useremail: '' }));
  }

  return (
    <div className="md:px-10 lg:px-0">
      <div className="block p-6 bg-white border rounded shadow-lg ">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-shark-500">Subscribe to Our Newsletter!</h5>
        <p className="font-normal text-shark-400">Stay Updated to the Technology Trends for Every Industry Niche.</p>
        <form
          method="POST"
          className="w-full relative"
          onSubmit={e => onSubmit(e)}
        >
          <div class="relative mt-6 mb-2">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-shark-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
            </div>
            <input
              placeholder="Email"
              id="email"
              name="email"
              type="email"
              required
              value={email?.useremail}
              onChange={e => setEmail({ ...email, useremail: e.target.value })}
              className="border  text-shark-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  "
            />
          </div>
          <div className="mb-2">
            {email?.loading ? <span>Loading...</span> : <span className={`${email?.success ? 'text-green-500' : "text-red-500"}`}> {email?.response}</span>}
          </div>
          <button type="submit" className={`${email?.response ? 'mt-0' : 'mt-2'} text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full`}>Subscribe</button>
        </form>
      </div>
    </div>
  )
}

export default SubscribeForm
