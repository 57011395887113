import React from "react"
import P from "../../core/P"
import H2 from "../../core/H2"

export const SimpleStacked = ({ title, subtitle, action1, action2, image }) => {
  return (
    <div className="bg-white">
      <div className="w-11/12 sm:w-4/5 mx-auto grid justify-center md:grid-cols-12  gap-2 py-6">
        <div
          className=" md:col-span-5 col-span-12 col-start-1 lg:max-w-none flex items-center "
          data-aos="fade-right"
        >
          <div
            className={` w-full rounded-lg flex flex-col md:ml-auto mx-auto justify-center`}
          >
            <div className="overflow-hidden flex justify-right">
              <div className="h-auto">
                {image && (
                  <img
                    src={image?.mediaItemUrl}
                    alt={image?.altText ? image.altText : "Contact Us"}
                    title="Tech Section"
                    loading="lazy"
                    className="rounded-lg"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="px-4 py-6 md:px-5 md:py-10 sm:max-w-lg lg:max-w-none md:col-span-7 col-span-12  flex justify-center md:justify-left items-center aos-init aos-animate"
          data-aos="fade-left"
        >
          <div>
            {title && (
              <H2
                title={title}
                className={"text-shark-500 text-center md:text-left"}
              />
            )}
            {subtitle && (
              <P
                title={subtitle}
                className={`text-shark-500 text-center md:text-left`}
                noCenter={true}
              />
            )}
            <div className="mt-4 flex justify-center md:justify-start">
              {action1 && (
                <div
                  className={`mt-5 mr-3  inline-flex justify-center lg:justify-start `}
                >
                  <a
                    href={action1.url}
                    className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
                  >
                    {action1.title}
                  </a>
                </div>
              )}
              {action2 && (
                <div
                  className={`mt-5 ml-3  inline-flex justify-center lg:justify-start`}
                >
                  <a
                    href={action2.url}
                    className="bg-transparent relative px-8 py-3 text-base leading-6 font-medium text-shark-500 border border-shark-400 hover:text-shark-600 hover:border-shark-600 rounded"
                  >
                    {action2.title}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleStacked
