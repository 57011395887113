import React, { useState } from "react"
import { navigate } from "gatsby"
import Countries from "../../../../static/countries.json"
import P from "../../core/P.js"
import H2 from "../../core/H2.js"

const ContactForm = () => {
  const [nda, setNda] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [message, setMessage] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [disabled, setDisabled] = useState(false)
  const [optionsCountry, setOptionsCountry] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(undefined)

  const changeHandler = e => {
    setCountryCode(e.target.value)
    // setCountryCode(e.label)
  }
  const handleOption = () => {
    const test = Countries
    setOptionsCountry(test)
  }

  const onSubmit = e => {
    e.preventDefault()
    setDisabled(true)
    const form = e.target
    const data = new FormData(form)
    data.set("countryCode", countryCode)
    data.set("nda", nda ? "1" : "0")

    // eslint-disable-next-line no-unused-vars
    const response = fetch(
      // "http://127.0.0.1:8080/api/web/contactus/extended_contact_submit",
      "https://api.webmobtech.com/wmtblog/api/web/contactus/extended_contact_submit",
      {
        method: "POST",
        body: data,
      }
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        window.scrollTo(0, 0)
        if (res.meta.status === "ok") {
          document.getElementById("contact-form").reset()
          setNda(false)
          navigate("/thank-you/")
        }
        setDisabled(false)
        setMessage(res.meta.message)
        setStatus(res.meta.status)
      })
  }
  const Notification = ({ status, message }) => {
    if (!status) return null
    return (
      status && (
        <div
          className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
            } p-4`}
        >
          <div className="flex">
            <div className="ml-3">
              <p
                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                  }`}
              >
                {message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setStatus(false)}
                  className={`inline-flex rounded-md p-1.5 ${status === "ok"
                    ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                    : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                    }  focus:outline-none transition ease-in-out duration-150`}
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  return (
    <div className="common-index lg:pt-36 pt-24 pb-24">
      <div className="overflow-hidden flex justify-center items-center">
        <div className="relative lg:px-20  px-10 pt-5 pb-20 bg-white rounded-md ">
          <form
            id="contact-form"
            method="POST"
            encType="multipart/form-data"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8"
            onSubmit={e => onSubmit(e)}
          >
            <div className="sm:col-span-2">
              <H2
                title={`Request for Project Proposal`}
                className={`text-xl text-shark-400 py-12 text-center`}
                noPad
              />
            </div>
            {status && (
              <div className="sm:col-span-2">
                <Notification status={status} message={message} />
              </div>
            )}
            <div className="sm:col-span-2">
              <label
                htmlFor="document"
                className="text-shark-500 font-medium block text-lg leading-5 "
              >
                1. Tell us about you and your project
              </label>
            </div>
            <div className="sm:col-span-2 mt-1 relative  shadow-sm">
                <input
                  placeholder="Name *"
                  id="fullName"
                  name="fullName"
                  required
                  className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                />
            </div>
            <div className="lg:col-span-1 md:col-span-2 col-span-1 mt-1 relative  shadow-sm">
                <input
                  placeholder="Email *"
                  id="emailId"
                  name="emailId"
                  type="email"
                  required
                  className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                />
            </div>
            <div className="lg:col-span-1 md:col-span-2 col-span-1 mt-1 relative  shadow-sm">
                <input
                  placeholder="Skype ID/WhatsApp No."
                  id="skypeOrWhatsapp"
                  name="skypeOrWhatsapp"
                  className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                />
            </div>
            <div className="sm:col-span-2 flex flex-wrap">
                <div className="lg:w-1/3 w-1/2 lg:pr-0 pr-2 mt-1 relative shadow-sm">
                    {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                    <select
                      placeholder="Country Code *"
                      aria-label="Country"
                      name="countryCode"
                      options={Countries}
                      value={countryCode}
                      onFocus={handleOption}
                      onChange={changeHandler}
                      style={{ color: "#6687ff" }}
                      required
                      className="rounded-md bg-transparent form-select py-2 px-4 block w-full h-full border border-solid text-gray-400 border-blue-300 transition ease-in-out duration-150"
                    >
                      <option
                        value=""
                        className="custom_option"
                        style={{ color: "#6687ff" }}
                      >
                        Country Code *
                      </option>
                      {optionsCountry && optionsCountry.map(e => (
                        <option
                          className="custom_option"
                          style={{ color: "#6687ff" }}
                          key={Math.random()}
                        >
                          {e.code}&nbsp;&nbsp;({e.dial_code})&nbsp;&nbsp;{e.flag}
                        </option>
                      ))}
                    </select>
                </div>
                <div className="lg:w-2/3 w-1/2 lg:pl-8  pl-2 mt-1 relative  shadow-sm">
                    <input
                      placeholder="Mobile Phone Number *"
                      type="tel"
                      id="contactNo"
                      name="contactNo"
                      required
                      className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                    />
                </div>
            </div>
            <div className="sm:col-span-2 mt-1 relative shadow-sm">
                <input
                  placeholder="Company Name"
                  id="companyName"
                  name="companyname"
                  className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                />
            </div>
            <div className="sm:col-span-2 mt-1 relative shadow-sm">
                <input
                  placeholder="Share reference links or name."
                  id="reference_link"
                  name="referenceLink"
                  type="text"
                  className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                />
            </div>
            <div className="sm:col-span-2 mt-1 relative shadow-sm">
                <textarea
                  placeholder="Share brief about your project requirement *"
                  id="desc"
                  name="desc"
                  rows="2"
                  required
                  className="rounded-md bg-transparent form-textarea resize-none py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                ></textarea>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="business_category"
                className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
              >
                2. What Categorizes Your Business the Best? *
              </label>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 gap-4 grid-cols-2 ">
                <div>
                  <label htmlFor="radio1">
                    <div className="text-center rounded-md">
                      <input
                        type="radio"
                        id="radio1"
                        value="Startup"
                        name="category"
                      />
                      <div className="customradio p-3 rounded-md">
                        <i
                          className={`icon-startups flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Startup`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio2">
                    <div className="text-center rounded-md">
                      <input
                        type="radio"
                        id="radio2"
                        value="SME"
                        name="category"
                      />
                      <div className="customradio p-3 rounded-md">
                        <i
                          className={`icon-sme flex justify-center py-3 mx-0`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`SME`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio3">
                    <div className="text-center rounded-md">
                      <input
                        type="radio"
                        id="radio3"
                        value="Enterprise"
                        name="category"
                      />
                      <div className="customradio p-3 rounded-md">
                        <i
                          className={`icon-large-scale flex justify-center py-3 mx-0`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Enterprise`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio4">
                    <div className="text-center rounded-md">
                      <div className="icon-card rounded-md">
                        <input
                          type="radio"
                          id="radio4"
                          name="category"
                          value="Agency"
                        />
                        <div className="customradio p-3 rounded-md">
                          <i
                            className={`icon-agency flex justify-center py-3 mx-0  `}
                            style={{ fontSize: "50px" }}
                          />
                          <P
                            title={`Agency`}
                            className={`font-semibold pb-3 text-center mx-3`}
                            noPad
                          />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="services_category"
                className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
              >
                3. What Services You are Looking For? *
              </label>
              <div className="grid lg:grid-cols-4 md:grid-cols-4 gap-4 grid-cols-2">
                <div>
                  <label htmlFor="radio5">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio5"
                        value="Web App"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-web_app_dev flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Web App`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio6">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio6"
                        value="Mobile App"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-mobile_app_dev flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Mobile App`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio7">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio7"
                        value="UI/UX"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-ui_ux_design flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`UI/UX`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio8">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio8"
                        value="IT Consulting"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-it_consulting flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`IT Consulting`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio9">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio9"
                        value="DevOps"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-devops flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`DevOps`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio10">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio10"
                        value="AR/VR"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-ar_vr flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`AR/VR`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio11">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio11"
                        value="IoT"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-iot flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Internet Of Things`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="radio12">
                    <div className="text-center hover:text-blue-500 bg-white rounded-md">
                      <input
                        type="checkbox"
                        id="radio12"
                        value="Hire Dedicated Developers"
                        name="services[]"
                      />
                      <div className="customcheck p-3 rounded-md">
                        <i
                          className={`icon-wadev_consumer flex justify-center py-3 mx-0 active:text-blue-500`}
                          style={{ fontSize: "50px" }}
                        />
                        <P
                          title={`Hire Dedicated Developers`}
                          className={`font-semibold pb-3 text-center mx-3`}
                          noPad
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="document"
                  className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
                >
                  4. Your Approx. Budget? *
                </label>
                <div className="flex flex-wrap my-2">
                  <div className="lg:w-1/4 md:w-1/4 w-1/2">
                    <label htmlFor="inlineRadio1">
                      <div className="text-center text-shark-200 hover:text-blue-500 bg-white rounded-md mx-2">
                        <input
                          type="radio"
                          id="inlineRadio1"
                          value="Less Than $10,000"
                          name="budget"
                        />
                        <div className="customradio p-3 rounded-md">
                          <i
                            className={`icon-less-than-10000 flex justify-center py-3 mx-0 active:text-blue-500`}
                            style={{ fontSize: "50px" }}
                          />
                          <P
                            title={`Less Than $10,000`}
                            className={`font-semibold pb-3 text-center mx-3`}
                            noPad
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="lg:w-1/4 md:w-1/4 w-1/2">
                    <label htmlFor="inlineRadio2">
                      <div className="text-center text-shark-200 hover:text-blue-500 bg-white rounded-md mx-2">
                        <input
                          type="radio"
                          id="inlineRadio2"
                          value="$10,000 to $25,000"
                          name="budget"
                        />
                        <div className="customradio p-3 rounded-md">
                          <i
                            className={` icon-10000-to-25000 flex justify-center py-3 mx-0`}
                            style={{ fontSize: "50px" }}
                          />
                          <P
                            title={`$10,000 to $25,000`}
                            className={`font-semibold pb-3 text-center mx-3`}
                            noPad
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="lg:w-1/4 md:w-1/4 w-1/2">
                    <label htmlFor="inlineRadio3">
                      <div className="text-center text-shark-200 hover:text-blue-500 bg-white rounded-md mx-2">
                        <input
                          type="radio"
                          id="inlineRadio3"
                          value="$25,000 to $50,000"
                          name="budget"
                        />
                        <div className="customradio p-3 rounded-md">
                          <i
                            className={`icon-25000-to-50000 flex justify-center py-3 mx-0`}
                            style={{ fontSize: "50px" }}
                          />
                          <P
                            title={`$25,000 to $50,000`}
                            className={`font-semibold pb-3 text-center mx-3`}
                            noPad
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="lg:w-1/4 md:w-1/4 w-1/2">
                    <label htmlFor="inlineRadio4">
                      <div className="text-center text-shark-200 hover:text-blue-500 bg-white rounded-md mx-2">
                        <div className="icon-card rounded-md">
                          <input
                            type="radio"
                            id="inlineRadio4"
                            value="$50,000+"
                            name="budget"
                          />
                          <div className="customradio p-3 rounded-md">
                            <i
                              className={`icon-50000 flex justify-center py-3 mx-0  `}
                              style={{ fontSize: "50px" }}
                            />
                            <P
                              title={`$50,000+`}
                              className={`font-semibold pb-3 text-center mx-3`}
                              noPad
                            />
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="sm:col-span-2">
              <div className="lg:col-span-1 md:col-span-2 col-span-1">
                <label
                  htmlFor="document"
                  className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
                >
                  5. Share the reference you like
                </label>
                <div className="mt-1 relative  shadow-sm">
                  <input
                    placeholder="Share reference links or name."
                    id="reference_link"
                    name="reference_link"
                    type="text"
                    className="rounded-md bg-transparent form-input py-2 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                  />
                </div>
              </div>
            </div> */}
            <div className="sm:col-span-2">
              <label
                htmlFor="document"
                className="text-shark-500 font-medium mb-3 block text-lg leading-5 "
              >
                5. Upload Project Documents.
              </label>
              <input
                onChange={event => setFile(event.target.files[0].name)}
                type="file"
                id="uploadFile"
                name="fileDoc"
                accept=".jpg, .jpeg, .png,.doc,.docx,.xml, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
              // style={{ display: "none" }}
              />
              {/* <label className="uploadFile" htmlFor="uploadFile">
                <div className="flex justify-center px-6 py-3 border-blue-300 border rounded w-full">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="mt-1 text-sm text-gray-600">
                      <div className="text-blue-400 block text-lg leading-5 transition duration-150 ease-in-out">
                        {fileName}
                      </div>
                    </p>
                  </div>
                </div>
              </label> */}
            </div>
            <div className="sm:col-span-2">
              <span className="inline-flex items-center my-3">
                <span
                  className={`${nda ? "bg-blue-500" : "bg-blue-300"
                    } relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                  role="checkbox"
                  aria-checked="false"
                  aria-hidden="true"
                  onClick={() => setNda(s => !s)}
                  id="nda"
                  name="nda"
                >
                  <span
                    className={` ${nda ? "translate-x-5" : "translate-x-0"
                      } inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                  ></span>
                </span>
                <span className="ml-2 block text-xs font-medium leading-5 text-shark-400">
                  Request Non-Disclosure Agreement for a Confidential
                  Consultation
                </span>
              </span>
            </div>
            <div className="sm:col-span-2">
              <span className=" inline-flex shadow-sm">
                {disabled ?
                  <button
                    type="submit"
                    disabled
                    className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                  >
                    Submit
                  </button> :
                  <button
                    type="submit"
                    className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                  >
                    Submit
                  </button>}
                <div className=" mt-5 inline-flex ">
                </div>
              </span>
            </div>
            {/* <div className="sm:col-span-2">
              <span className="mt-5 block text-xs font-medium leading-5 text-shark-400">
                By submitting this form you agree to receive upto 3 promotional emails every month. 
              </span>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
